import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col,
} from "reactstrap";

const questions = [
  {
    question: "Do you charge equity?",
    answer:
      "No, we do not. We are here to celebrate creativity, not stifle it. Altough we provide a variety of services, many of which incurs a cost to us, we do not charge any equity in return.",
  },
  {
    question: "How many students participate in Launchpad?",
    answer:
      "Our ambitions are limitless, but our resources are not. We can only support about 20 students in any given year. As such, we are selective about who we work with.",
  },
  {
    question: "How do I get started with an application?",
    answer:
      "Get in touch with us. Let's talk about where you are, where you want to go, and what makes you happy.",
  },
  {
    question: "What happens after I express my intent to join Launchpad?",
    answer:
      "If all goes well, we invite you to a weekend retreat (paid for by us). You get to learn a ton of things, and then you must make a formal submission.",
  },
  {
    question: "What follows after the retreat?",
    answer:
      "One month of summer, and a chance to register for a two-semester, 6 credit course course. We get a lot of applications for the course, we only pick 20. Once you are in, we will start investing our time and resources on you.",
  },
  {
    question: "What prior knowledge do I need?",
    answer:
      "Not much, actually. That's the beauty of it. Most prior participants had never built any big products or started companies. However, they all shared in common these traits: passion, perseverence, ambition, and playfullness.",
  },
];

function FAQ() {
  const [openedCollapse, setOpenedCollapse] = React.useState(null);

  const renderQuestions = () => {
    return questions.map((q, index) => (
      <Card key={index}>
        <CardHeader id="headingOne" className="bg-light">
          <h5 className="mb-0">
            <Button
              aria-expanded={openedCollapse === index}
              onClick={() =>
                setOpenedCollapse(openedCollapse === index ? null : index)
              }
              className="w-100 text-primary text-left"
              color="link"
              type="button"
            >
              {q.question}
              <i className="ni ni-bold-down float-right pt-1"></i>
            </Button>
          </h5>
        </CardHeader>
        <Collapse isOpen={openedCollapse === index}>
          <CardBody className="opacity-8">{q.answer}</CardBody>
        </Collapse>
      </Card>
    ));
  };

  return (
    <>
      <div className="accordion-1 pt-6 bg-light">
        <Container>
          <Row>
            <Col className="mx-auto text-center" md="7">
              <h2 className="display-3 mb-3 mt-5">
                Frequently asked questions
              </h2>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="12">
              <div className="accordion" id="accordionExample">
                {renderQuestions()}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FAQ;
