import React from "react";
import { Container } from "reactstrap";

function WhatWeOffer(props) {
  return (
    <div className="section bg-default what-we-offer">
      <Container>
        <div className="wwo-header">
          <h3 className="display-3">What We Offer</h3>
        </div>
        <div className="content">
          <p>
            We offer everything early founders and engineers need to generate
            great ideas, turn ideas into products, and use these products to
            capture markets.
          </p>
          <p>
            We are not passive educators. We roll up our sleeves and get to work
            alongside you on strategy, product development, and value
            proposition. From technology to sales, patents to legal
            incorporation, we will deliver pretty much anything you can imagine,
            and many others that you cannot imagine.
          </p>
        </div>
        <div className="wwo-bubble-set">
          <i className="icon ni ni-atom"></i>
          <i className="icon ni ni-spaceship"></i>
          <i className="icon ni ni-mobile-button"></i>
          <i className="icon ni ni-settings"></i>
          <i className="icon ni ni-favourite-28"></i>
        </div>
      </Container>
    </div>
  );
}

export default WhatWeOffer;
