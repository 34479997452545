import React from "react";
import { Container } from "reactstrap";

function Mission(props) {
  return (
    <div className="mission-launchpad">
      <Container className="mission-container">
        <div className="mission-content">
          <h3 className="display-4">Our Mission</h3>
          <p>
            Beyond wealth creation and shareholder value is an unlimited
            resource to create a better world and a lasting legacy. It's called
            purpose. The purpose of all great companies is to improve the
            quality of human life.
            <br />
            <br />
            Our purpose to help launch these companies.
          </p>
        </div>
      </Container>
    </div>
  );
}

export default Mission;
