import React from "react";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Button,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
} from "reactstrap";

// Core Components
const items = [
  {
    content: (
      <Container>
        <Row className="align-items-center">
          <Col className="ml-auto" md="5">
            {/* <img
              className="showcase-logo"
              src={require("assets/img/logo.png")}
              alt="Company Logo"
            /> */}
            <CardTitle tag="h3">SecureMeeting</CardTitle>
            <h4 className="lead">
              Secure Meeting is on a mission to advance human rights and freedom
              of speech by creating privacy preserving communication platforms
              and technologies for all of mankind.
            </h4>
            <NavLink to="/portfolio">
              <Button
                className="mt-3"
                color="none"
                style={{ backgroundColor: "#0D1B4E", color: "#fff" }}
              >
                Read more
              </Button>
            </NavLink>
          </Col>
          <Col className="p-md-5 ml-auto order-first order-md-last" md="5">
            <div className="p-3 text-center">
              <img
                alt="..."
                className="img-fluid rounded"
                src={require("assets/img/CompanyLogos/securemeeting.png")}
              ></img>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    content: (
      <Container className="mt-4 mt-md-5">
        <Row className="align-items-center">
          <Col className="ml-auto" md="5">
            <CardTitle tag="h3">Truthable</CardTitle>
            <h4 className="lead">
              We are committed to providing services that help remove the burden
              of communication. We want to enable communities to talk to data
              rather than just mindlessly consuming or being forced information
              that is not pertinent.
            </h4>
            <NavLink to="/portfolio">
              <Button
                className="mt-3"
                color="none"
                style={{ backgroundColor: "#21EA50", color: "#fff" }}
              >
                Read more
              </Button>
            </NavLink>
          </Col>
          <Col className="p-md-5 ml-auto order-first order-md-last" md="5">
            <div className="p-3 text-center">
              <img
                alt="..."
                className="img-fluid rounded truthable"
                src={require("assets/img/CompanyLogos/truthable.png")}
              ></img>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
  {
    content: (
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col className="ml-auto" md="5">
            {/* <img
              className="showcase-logo"
              src={require("assets/img/logo.png")}
              alt="Company Logo"
            /> */}
            <CardTitle tag="h3">SquareOneJobs</CardTitle>
            <h4 className="lead">
              Our mission is to support and empower socially disadvantaged
              groups to develop their potential and find careers they’re
              passionate about by providing them with the connections, tools,
              and resources they need along the way.
            </h4>
            <NavLink to="/portfolio">
              <Button
                className="mt-3"
                color="none"
                style={{ backgroundColor: "#51cc8e", color: "#fff" }}
              >
                Read more
              </Button>
            </NavLink>
          </Col>
          <Col className="p-md-5 ml-auto order-first order-md-last" md="5">
            <div className="p-3 text-center">
              <img
                alt="..."
                className="img-fluid rounded"
                src={require("assets/img/CompanyLogos/squareonejobs.png")}
              ></img>
            </div>
          </Col>
        </Row>
      </Container>
    ),
  },
];

function Showcase() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  return (
    <>
      <div className="testimonials-1 showcase">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <h2 className="display-3">Showcase</h2>
            </Col>
          </Row>
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            className="carousel-team"
            id="carousel-testimonials"
          >
            {items.map((item, key) => {
              return (
                <CarouselItem
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                  key={key}
                >
                  {item.content}
                </CarouselItem>
              );
            })}
            <a
              className="carousel-control-prev"
              data-slide="prev"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <i className="ni ni-bold-left"></i>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              data-slide="next"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <i className="ni ni-bold-right"></i>
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
        </Container>
      </div>
    </>
  );
}

export default Showcase;
