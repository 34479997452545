import Navbar from "components/Landing/Navbar";
import React from "react";
import { Button } from "reactstrap";

function Donate(props) {
  const openEmail = () => {
    window.location.href = "mailto:miyengar@stevens.edu";
  };
  return (
    <>
      <Navbar />
      <div
        className="donate"
        style={{
          backgroundImage:
            "url(" + require("assets/img/ill/success-bg.svg") + ")",
        }}
      >
        <div className="content">
          <h1 className="display-1">Interested in Donating?</h1>
          <p className="my-4">
            We'd love to have a chat with you to build a strong relationship.
            Send us an email to get started.
          </p>
          <Button color="primary" onClick={openEmail}>
            Email
          </Button>
        </div>
      </div>
    </>
  );
}

export default Donate;
