import React from "react";
import { NavLink } from "react-router-dom";

import iubble from "assets/img/CompanyLogos/iubble.png";
import Noteworth from "assets/img/CompanyLogos/noteworth.png";
import Fintech from "assets/img/CompanyLogos/fintech.webp";
import SecureMeeting from "assets/img/CompanyLogos/securemeeting.png";
import Dexterity from "assets/img/CompanyLogos/dexterity.png";
import S1J from "assets/img/CompanyLogos/squareonejobs.png";

import { Button } from "reactstrap";

const companies = [
  {
    name: "iubble",
    logo: iubble,
    description:
      "Status: Acquired. Founded in 2012, iubble built a web-browser that made it easy to discover, organize and share web-content. Awarded Thomas Edison Patent Inventor award by state of NJ.",
  },
  {
    name: "Noteworth",
    logo: Noteworth,
    description:
      "Noteworth enables healthcare systems to easily conceptualize, create and operationalize expansions of care. Founded in 2015, and secured $5+ millions in seed funding.",
  },
  {
    name: "Fintech Studios",
    logo: Fintech,
    description:
      "AI based search and analytics platform for wall street. Formed in 2016, the company has raised millions in 2017 and is headed for series-A.",
  },
  {
    name: "SecureMeeting",
    logo: SecureMeeting,
    description:
      "This non-profit delivers safe, easy and always-on meeting places for geographically dispersed participants. Launched in April 2018, this site has already hosted more than 500 conversations.",
  },
  {
    name: "",
    logo: Dexterity,
    description:
      "This non-profit delivers safe, easy and always-on meeting places for geographically dispersed participants. Launched in April 2018, this site has already hosted more than 500 conversations.",
  },
  {
    name: "SquareOneJobs",
    logo: S1J,
    description:
      "Job site that supports and empowers socially disadvantaged groups to develop their potential and find careers they’re passionate about.",
  },
];

function SuccessStories(props) {
  const renderCompanies = () => {
    return companies.map((company, i) => {
      return (
        <NavLink to="/portfolio" key={i} className="company-container">
          <img
            className="logo"
            src={company.logo}
            alt={`${company.name}'s Logo`}
          />
          <h3 className="name">{company.name}</h3>
          <p className="description">{company.description}</p>
        </NavLink>
      );
    });
  };
  return (
    <div
      className="success-stories section"
      style={{
        backgroundImage:
          "url(" + require("assets/img/ill/success-bg.svg") + ")",
      }}
    >
      <div className="container">
        <h3 className="display-3 text-center">Success Stories</h3>
        <div className="text-center">
          <NavLink to="portfolio">
            <Button className="btn btn-outline-default btn-sm" outline>
              See more
            </Button>
          </NavLink>
        </div>
        <div className="success-stories-container">{renderCompanies()}</div>
      </div>
    </div>
  );
}

export default SuccessStories;
