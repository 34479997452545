import Navbar from "components/Landing/Navbar";
import React from "react";
import { Button } from "reactstrap";

function Login(props) {
  const openEmail = () => {
    window.location.href = "mailto:miyengar@stevens.edu";
  };
  return (
    <>
      <Navbar />
      <div
        className="login"
        style={{
          backgroundImage:
            "url(" + require("assets/img/ill/success-bg.svg") + ")",
        }}
      >
        <div className="content">
          <h1 className="display-1">Login</h1>
          <p className="my-4">
            As some of our companies are early-stage development, for legal
            reasons we do not showcase them to the public. Send us an email if
            you're interested in more.
          </p>
          <Button color="primary" onClick={openEmail}>
            Chat with us
          </Button>
        </div>
      </div>
    </>
  );
}

export default Login;
