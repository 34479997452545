import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-design-system.scss?v1.0.0";

import Launchpad from "./views/Launchpad";
import Portfolio from "views/Portfolio";
import Login from "views/Login";
import Apply from "./views/Apply";
import Donate from "views/Donate";
import Error from "./components/Landing/Error";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/apply" exact render={(props) => <Apply {...props} />} />
      <Route
        path="/portfolio"
        exact
        render={(props) => <Portfolio {...props} />}
      />
      <Route path="/login" exact render={(props) => <Login {...props} />} />
      <Route path="/donate" exact render={(props) => <Donate {...props} />} />
      <Route path="/not-found" exact render={(props) => <Error {...props} />} />
      <Route path="/" exact render={(props) => <Launchpad {...props} />} />
      <Redirect to="/not-found" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
