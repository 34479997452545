import SquareOneJobs from "assets/img/CompanyLogos/squareonejobs.png";
import Truthable from "assets/img/CompanyLogos/truthable.png";
import SecureMeeting from "assets/img/CompanyLogos/securemeeting.png";
import Tir from "assets/img/CompanyLogos/tir.png";
import Quae from "assets/img/CompanyLogos/quae.jpg";

export default [
  {
    name: "SecureMeeting",
    logo: SecureMeeting,
    color: "#0D1B4E",
    catchphrase: "",
    url: "www.securemeeting.org",
    socials: [
      {
        type: "medium",
        url: "https://medium.com/securemeeting",
      },
      {
        type: "linkedin",
        url: "https://www.linkedin.com/company/securemeeting/",
      },
      {
        type: "instagram",
        url: "https://www.instagram.com/securemeeting/",
      },
      {
        type: "twitter",
        url: "https://twitter.com/secure_meeting",
      },
    ],
    mission:
      "Secure Meeting is on a mission to advance human rights and freedom of speech by creating privacy preserving communication platforms and technologies for all of mankind.",
    founded: "2021",
    team:
      "Bryan Kyritz, Andrew D'Angelo, Marlee Kopetsky, Mukund Iyengar, Justin Trugman",
  },
  {
    name: "Truthable",
    logo: Truthable,
    color: "#21EA50",
    catchphrase: "Revolutionize how organizations communicate",
    url: "www.truthable.ai",
    mission:
      "We are committed to providing services that help remove the burden of communication. We want to enable communities to talk to data rather than just mindlessly consuming or being forced information that is not pertinent.",
    founded: "2020",
    founders: "Jayden Pereira",
    team: "Jayden Pereira, David Rocha, Nicholas DiMeglio",
  },
  {
    name: "SquareOneJobs",
    logo: SquareOneJobs,
    color: "#51cc8e",
    catchphrase: "Find your potential. Unlock your purpose.",
    url: "www.squareonejobs.com",
    socials: [
      {
        type: "medium",
        url: "https://medium.com/squareonejobs",
      },
      {
        type: "linkedin",
        url: "https://www.linkedin.com/company/squareonejobs/",
      },
      {
        type: "facebook",
        url: "https://www.linkedin.com/company/squareonejobs/",
      },
      {
        type: "instagram",
        url: "https://www.linkedin.com/company/squareonejobs/",
      },
    ],
    mission:
      "Our mission is to support and empower socially disadvantaged groups to develop their potential and find careers they’re passionate about by providing them with the connections, tools, and resources they need along the way.",
    founded: "2019",
    founders: "Nathan Renner, Greg LaVersa",
    team: "Nathan Renner, Greg LaVersa",
  },
  {
    name: "DroneHQ",
    color: "#1408FF",
    catchphrase: "Your eyes in the sky",
    url: "www.dronehq.dev",
    mission:
      "At DroneHQ, our mission is to give drones the gift of vision and intelligence.",
    founded: "2020",
    founders: "Zachary Marvin, Nicholas Cali",
    team: "Zachary Marvin, Nicholas Cali",
  },
  {
    name: "Tir",
    logo: Tir,
    color: "#76cddf",
    catchphrase: "Revolutionize the way you learn",
    url: "www.tir.one",
    socials: [
      {
        type: "medium",
        url: "https://medium.com/@tir.one",
      },
      {
        type: "linkedin",
        url: "https://www.linkedin.com/company/tir-one",
      },
      {
        type: "instagram",
        url: "https://www.instagram.com/edu.tir.one/",
      },
      {
        type: "twitter",
        url: "https://twitter.com/edu_tir_one",
      },
    ],
    mission: 'To eliminate the "one size fits all" approach to education.',
    founded: "2020",
    founders: "Anthony Mauceri, Marlee Kopetsky",
    team: "Anthony Mauceri, Marlee Kopetsky",
  },
  {
    name: "Quae",
    logo: Quae,
    color: "#7026AB",
    catchphrase: "Vote Every Day",
    url: "www.quae.app",
    socials: [
      {
        type: "instagram",
        url: "https://www.instagram.com/quaeapp/",
      },
      {
        type: "twitter",
        url: "https://twitter.com/Quae_App",
      },
      {
        type: "facebook",
        url: "https://www.facebook.com/quaeapp/",
      },
      {
        type: "linkedin",
        url: "https://www.linkedin.com/company/quae/",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/channel/UC8Mkk0uEYtbCs0TPk1ix94g",
      },
    ],
    mission: "Allow everyone to be represented.",
    founded: "2020",
    founders: "Samuel Schmitt, Brendan Probst",
    team: "Samuel Schmitt, Brendan Probst",
  },
  {
    name: "Seray",
    catchphrase: "Seray, your skin care bestie",
    url: "www.serayskin.com",
    socials: [
      {
        type: "instagram",
        url: "https://www.instagram.com/serayskin/",
      },
    ],
    mission: "Giving teens the confidence without breaking the bank!",
    founded: "2020",
    founders: "Alyssa Hernandez, Serena Lee",
    team: "Alyssa Hernandez, Serena Lee",
  },
  // {
  //   name: "",
  //   logo: "",
  //   color: "",
  //   catchphrase: "",
  //   url: "",
  //   socials: [
  //     {
  //       type: "linkedin",
  //       url: "",
  //     },
  //     {
  //       type: "facebook",
  //       url: "",
  //     },
  //     {
  //       type: "instagram",
  //       url: "",
  //     },
  //   ],
  //   mission:
  //     "",
  //   founded: "",
  //   founders: "",
  //   team: "",
  // },
];
