import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Button } from "reactstrap";

import Lottie from "lottie-react";

import Hamburger from "assets/animations/hamburger.json";
import Logo from "assets/img/logo.png";

const routes = [
  {
    name: "apply",
    title: "Apply",
    to: "/apply",
  },
  {
    name: "portfolio",
    title: "Portfolio",
    to: "/portfolio",
  },
  // {
  //   name: "resources",
  //   title: "Resources",
  //   to: "/resources",
  // },
  {
    name: "login",
    title: "Login",
    to: "/login",
  },
];

function Navbar(props) {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [shadow, setShadow] = useState(false);
  const hamburgerRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      return window.pageYOffset < 10 ? setShadow(false) : setShadow(true);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [setShadow]);

  const toggleDrawer = () => {
    if (!drawerOpened) {
      hamburgerRef.current.playSegments([0, 24], true);
      setDrawerOpened(true);
    } else {
      hamburgerRef.current.playSegments([24, 0], true);
      setDrawerOpened(false);
    }
  };

  const renderRoutes = () => {
    return routes.map((route, index) => {
      return (
        <NavLink
          key={index}
          to={route.to}
          className={`nav-link ${index === routes.length - 1 ? "last" : null} ${
            route.to === pathname ? "active" : null
          }`}
        >
          {route.title}
        </NavLink>
      );
    });
  };

  return (
    <>
      <div className={`navbar-launchpad ${drawerOpened ? "opened" : null}`}>
        <div className={`navbar-container ${shadow ? "shadow" : null}`}>
          <NavLink className="nav-left" to="/">
            <img src={Logo} alt="Launchpad logo" className="logo" />
            <h2 className="logo-text">launchpad@stevens</h2>
          </NavLink>
          <div className="hamburger-icon">
            <Lottie
              lottieRef={hamburgerRef}
              animationData={Hamburger}
              loop={false}
              autoplay={false}
              onClick={toggleDrawer}
            />
          </div>
          <div className="nav-right">
            {renderRoutes()}

            <a
              href="https://connect.stevens.edu/makeagift/fundsearch?fid=qrmy5fWj9gQ%3d&fdesc=YSRogcaL9hPFmO0Y6HFR9p0ygdgLvT6T"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="btn-primary" color="primary">
                <span>Donate</span>
              </Button>
            </a>
          </div>
        </div>
        <div className={`nav-drawer ${drawerOpened ? "opened" : null}`}>
          <h3 className="nav-title">Navigation</h3>
          <NavLink to="/apply" className="nav-link">
            Apply
          </NavLink>
          <NavLink to="/portfolio" className="nav-link">
            Portfolio
          </NavLink>
          {/* <NavLink to="/resources" className="nav-link">
            Resources
          </NavLink> */}
          <NavLink to="/login" className="nav-link last">
            Login
          </NavLink>
          <a
            href="https://connect.stevens.edu/makeagift/fundsearch?fid=qrmy5fWj9gQ%3d&fdesc=YSRogcaL9hPFmO0Y6HFR9p0ygdgLvT6T"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="btn-primary" color="primary">
              <span>Donate</span>
            </Button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Navbar;
