import React, { useState } from "react";
import Navbar from "components/Landing/Navbar.jsx";
import companies from "../companies";
import CompanyModal from "components/Portfolio/CompanyModal";

function Portfolio(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [company, setCompany] = useState(false);

  const handleModal = (comp) => {
    if (!company) {
      setCompany(comp);
      setIsOpen(true);
    } else {
      setCompany(false);
      setIsOpen(false);
    }
  };

  return (
    <>
      <div
        className={`overlay-bg ${isOpen ? "active" : null}`}
        onClick={handleModal}
      />
      <CompanyModal
        company={company}
        active={isOpen}
        handleModal={handleModal}
      />
      <Navbar shadow={false} />
      <div className={`nav-padding portfolio ${isOpen ? "noscroll" : null}`}>
        {companies.map((company, index) => (
          <div
            key={index}
            className="company-card"
            onClick={() => handleModal(company)}
          >
            <h2>{company.name}</h2>
          </div>
        ))}
      </div>
    </>
  );
}

export default Portfolio;
