import React, { useRef } from "react";
import { Container, Col, Row, Button } from "reactstrap";
import Lottie from "lottie-react";

import LaunchAnimation from "assets/animations/launch.json";
import ElevateAnimation from "assets/animations/elevate.json";
import PreideaAnimation from "assets/animations/preidea.json";
import BuildAnimation from "assets/animations/build.json";
import { NavLink } from "react-router-dom";

const launchInteractivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.2],
      type: "stop",
      frames: [0],
    },
    {
      visibility: [0.2, 0.4],
      type: "seek",
      frames: [0, 44],
    },
    {
      visibility: [0.4, 0.75],
      type: "loop",
      frames: [44, 94],
    },
    {
      visibility: [0.75, 1],
      type: "stop",
      frames: [94],
    },
  ],
};
const buildInteractivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.3],
      type: "stop",
      frames: [90],
    },
    {
      visibility: [0.3, 0.75],
      type: "play",
      frames: [0, 90],
    },
    {
      visibility: [0.75, 1],
      type: "stop",
      frames: [90],
    },
  ],
};
const elevateInteractivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.3],
      type: "stop",
      frames: [174],
    },
    {
      visibility: [0.3, 0.75],
      type: "play",
      frames: [0, 174],
    },
    {
      visibility: [0.75, 1],
      type: "stop",
      frames: [174],
    },
  ],
};
const preideaInteractivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.3],
      type: "stop",
      frames: [1],
    },
    {
      visibility: [0.3, 0.75],
      type: "play",
    },
    {
      visibility: [0.75, 1],
      type: "stop",
      frames: [180],
    },
  ],
};

function HowItWorks(props) {
  const launchRef = useRef();
  const buildRef = useRef();
  const elevateRef = useRef();
  const preideaRef = useRef();

  // const handleHover = (type) => {
  //   if (type === "launch") launchRef.current.playSegments([74, 143], true);
  // };

  return (
    <div className="section how-it-works bg-light skew-separator skew-top">
      <Container>
        <h2 className="display-3 text-center mt-7 mb-0">How it Works</h2>
        <Row className="stage align-items-center justify-content-center">
          <Col md="5">
            <h3 className="text-secondary display-2 my-0">• • •</h3>
            <h3 className="mb-3">Pre-idea Stage</h3>
            <p>
              We partner early. We're comfortable with the rough imperfections
              of early stage ideas. Let's chat.
            </p>
          </Col>
          <Col md="5" className="order-first order-md-last">
            <div className="lottie mx-auto">
              <Lottie
                lottieRef={preideaRef}
                animationData={PreideaAnimation}
                interactivity={preideaInteractivity}
              />
            </div>
          </Col>
        </Row>
        <Row className="stage align-items-center justify-content-center">
          <Col md="5">
            <div className="lottie">
              <Lottie
                lottieRef={elevateRef}
                animationData={ElevateAnimation}
                interactivity={elevateInteractivity}
              />
            </div>
          </Col>
          <Col md="5">
            <h3 className="text-warning display-2 my-0 ">• • •</h3>
            <h3 className="mb-3">Elevate</h3>
            <p>
              Attend our retreats. Take our courses. Meet co-founders. Learn how
              to start a movement.
            </p>
          </Col>
        </Row>
        <Row className="stage align-items-center justify-content-center">
          <Col md="5">
            <h3 className="text-primary display-2 my-0">• • •</h3>
            <h3 className="mb-3">Build</h3>
            <p>
              Build awesome products. Invent ideas, file patents. Develop
              customers.
            </p>
          </Col>
          <Col md="5" className="order-first order-md-last">
            <div className="lottie mx-auto">
              <Lottie
                lottieRef={buildRef}
                animationData={BuildAnimation}
                interactivity={buildInteractivity}
              />
            </div>
          </Col>
        </Row>
        <Row className="stage align-items-center justify-content-center">
          <Col md="5">
            <div className="lottie mx-auto">
              <Lottie
                lottieRef={launchRef}
                animationData={LaunchAnimation}
                interactivity={launchInteractivity}
              />
            </div>
          </Col>
          <Col md="5">
            <h3 className="text-secondary display-2 my-0">• • •</h3>
            <h3 className="mb-3">Launch</h3>
            <p>Incorporate. Scale and grow.</p>
            <NavLink to="/apply">
              <Button className="" color="secondary" type="button" outline>
                Get Started
              </Button>
            </NavLink>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HowItWorks;
