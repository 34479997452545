import React from "react";
import { NavLink } from "react-router-dom";

import Close from "components/svgs/Close";
import Linkedin from "components/svgs/Linkedin";
import Facebook from "components/svgs/Facebook";
import Instagram from "components/svgs/Instagram";
import Twitter from "components/svgs/Twitter";
import Medium from "components/svgs/Medium";
import Youtube from "components/svgs/Youtube";

function CompanyModal({ company, active, handleModal }) {
  const renderSocials = () => {
    return company.socials.map((social, index) => {
      let Component;
      if (social.type === "linkedin") Component = Linkedin;
      else if (social.type === "facebook") Component = Facebook;
      else if (social.type === "instagram") Component = Instagram;
      else if (social.type === "twitter") Component = Twitter;
      else if (social.type === "medium") Component = Medium;
      else if (social.type === "youtube") Component = Youtube;
      return (
        <a
          key={index}
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Component fill={company.color ? company.color : "#20763b"} />
        </a>
      );
    });
  };

  return (
    <div className={`company-modal ${active ? "active" : null}`}>
      <Close className="back-icon" onClick={handleModal} />
      {company ? (
        <div className="content">
          {company.logo && (
            <img
              src={company.logo}
              alt={`${company.name}'s Logo`}
              style={{ height: company.name === "Truthable" ? "2em" : "4em" }}
            />
          )}
          {company.name && <h2>{company.name}</h2>}
          {company.catchphrase && (
            <p className="subtitle">{company.catchphrase}</p>
          )}
          {company.url && (
            <a
              className="link"
              style={{ color: company.color ? company.color : "#20763b" }}
              href={`https://${company.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {company.url}
            </a>
          )}
          {company.socials ? (
            <div className="socials">{renderSocials()}</div>
          ) : null}
          <div className="c-info-container">
            {company.mission && (
              <div className="c-info">
                <h3>Mission</h3>
                <p>{company.mission}</p>
              </div>
            )}
            {company.founded && (
              <div className="c-info">
                <h3>Founded</h3>
                <p>{company.founded}</p>
              </div>
            )}
            {company.founders && (
              <div className="c-info">
                <h3>Founders</h3>
                <p>{company.founders}</p>
              </div>
            )}
            {company.team && (
              <div className="c-info">
                <h3>Team</h3>
                <p>{company.team}</p>
              </div>
            )}
          </div>
          <p className="learn-more">
            <NavLink to="/login">Login</NavLink> to see pitch decks, videos, and
            more!
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default CompanyModal;
