import React from "react";

import Showcase from "components/Landing/Showcase.js";
import Header from "components/Landing/Header";
import Navbar from "components/Landing/Navbar.jsx";
import FAQ from "components/Landing/FAQ";
import Contact from "components/Landing/ContactUs";
import Footer from "components/Landing/Footer";
import Mission from "components/Landing/Mission";
import HowItWorks from "components/Landing/HowItWorks";
import BuildAwesomeProducts from "components/Landing/BuildAwesomeProducts";
import WhatWeOffer from "components/Landing/WhatWeOffer";
import SuccessStories2 from "components/Landing/SuccessStories.jsx";

function Launchpad(props) {
  return (
    <div>
      <Navbar type="white" />
      <div className="wrapper">
        <Header />
        <Showcase />
        <HowItWorks />
        <WhatWeOffer />
        <BuildAwesomeProducts />
        <SuccessStories2 />
        <FAQ />
        <Contact />
        <Mission />
        <Footer />
      </div>
    </div>
  );
}

export default Launchpad;
