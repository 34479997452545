import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import Navbar from "./Navbar";

function Error() {
  return (
    <>
      <Navbar />
      <div className="wrapper">
        <div className="page-header error-page">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/ill/success-bg.svg") + ")",
            }}
          ></div>
          <Container>
            <Row>
              <Col className="text-center" md="12">
                <h1 className="title">404</h1>
                <p className="lead">Page not found</p>
                <h4 className="description text-default">
                  Oops! Looks like you got lost.
                </h4>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Error;
