import React from "react";

import Launchpad from "components/svgs/Launchpad";
//import Email from "./svgs/Email";
//import Instagram from "./svgs/Instagram";
//import Facebook from "./svgs/Facebook";
//import Linkedin from "./svgs/Linkedin";
import LineFooter from "components/svgs/LineFooter";

function Footer(props) {
  return (
    <div className="footer-launchpad">
      <LineFooter className="line-footer" />
      <div className="container footer-container">
        <div className="footer-left">
          <div className="logo-container">
            <Launchpad />
            <h2 className="logo-text">launchpad@stevens</h2>
          </div>
          <p>We help the daring build enduring companies</p>
        </div>
        <div className="footer-right">
          <div className="socials">
            {/* <Button
              className="btn-icon-only rounded-circle"
              color={"white"}
              type="button"
            >
              <i className="fab fa-facebook"></i>
            </Button> */}
            {/* <Email />
            <Linkedin />
            <Facebook />
            <Instagram /> */}
          </div>
          <p className="copyright">© 2021 launchpad. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
