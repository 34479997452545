import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function BuildAwesomeProducts() {
  return (
    <>
      <div className="section features-4">
        <Container>
          <Row>
            <Col className="text-center mx-auto" md="8">
              <h3 className="display-3">Build Awesome Products</h3>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center mt-6">
            <Col className="p-sm-0" lg="10">
              <Row>
                <Col md="6">
                  <div className="info info-hover-warning text-center">
                    <div className="icon icon-shape bg-warning shadow rounded-circle ">
                      <i className="ni ni-hat-3 text-white"></i>
                    </div>
                    <h5 className="info-title">Learn</h5>
                    <p className="description opacity-8 pr-0">
                      Build professional grade products with confidence. We
                      teach (and sponsor) Web (React), Python, Mobile (React
                      Native), JavaScript, TensorFlow, Node, MongoDB, and Agile
                      Software Development.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="info info-hover-secondary text-center">
                    <div className="icon icon-shape bg-secondary shadow rounded-circle">
                      <i className="ni ni-atom text-white"></i>
                    </div>
                    <h5 className="info-title">Developer Accounts</h5>
                    <p className="description opacity-8 pr-0">
                      We sponsor developer license(s) for Apple, Google, and/or
                      Microsoft. Also, private GitHub, JIRA, and Slack.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-lg-2">
                <Col md="6">
                  <div className="info info-hover-default text-center">
                    <div className="icon icon-shape bg-default shadow rounded-circle">
                      <i className="ni ni-cloud-upload-96 text-white"></i>
                    </div>
                    <h5 className="info-title">Cloud</h5>
                    <p className="description opacity-8 pr-0">
                      Deploy full-stack web/mobile solutions. We sponsor your
                      cloud-computing/GPU needs (GPU-clusters, AWS, Azure and/or
                      Google-cloud).
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="info info-hover-primary text-center">
                    <div className="icon icon-shape bg-primary shadow rounded-circle">
                      <i className="ni ni-controller text-white"></i>
                    </div>
                    <h5 className="info-title">Toys</h5>
                    <p className="description opacity-8 pr-0">
                      Powerful toys. Servers, routers, mobile phones, TVs, XBox,
                      Oculus, Holo-lens. Even drones. If we're missing
                      something, we'll get it.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default BuildAwesomeProducts;
