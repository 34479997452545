import * as React from "react";

function LineFooter(props) {
  return (
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 1440 289"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M237.5 30C148.5 115.5 0 288 0 288h1440S538 59 350 14.5C284-1.123 280-10.828 237.5 30z"
        fill="#20763B"
        stroke="#20763B"
      />
    </svg>
  );
}

export default LineFooter;
