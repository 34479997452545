import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";

// Core Components

function Header() {
  return (
    <>
      <header className="section section-hero section-shaped">
        <div className="page-header">
          <img
            alt="..."
            className="bg-image"
            style={{ marginTop: 110 }}
            src={require("assets/img/ill/header-bg.svg")}
          ></img>
          <Container>
            <Row className="text-center justify-content-center header-text">
              <Col lg="8">
                <h1 className="display-1 ">Build Something Massive</h1>
              </Col>
              <Col lg="6">
                <h5 className="">
                  We help innovators transition from science projects to
                  high-growth companies
                </h5>
                <NavLink to="/apply">
                  <Button
                    className="btn-outline-primary mt-3 mb-sm-0 "
                    color="transparent"
                  >
                    <span className="btn-inner--text">Apply</span>
                  </Button>
                </NavLink>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
}

export default Header;
